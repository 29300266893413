<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>{{ $t('create') }} {{ $tc('user', 1) }}</span>
    </div>
    <el-form
      ref="form"
      v-loading="form.loading"
      :model="form.model"
      :rules="form.rules"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="$t('email')" prop="userEmail">
            <el-input
              v-model="form.model.userEmail"
              :maxlength="124"
              @change="onChangeEmail"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$tc('user', 1)" prop="userName">
            <el-input v-model="form.model.userName" :maxlength="24"></el-input>
          </el-form-item>
          <el-form-item :label="$tc('name', 1)" prop="userFirstName">
            <el-input
              v-model="form.model.userFirstName"
              :maxlength="64"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('surname')" prop="userLastName">
            <el-input
              v-model="form.model.userLastName"
              :maxlength="64"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('phone')" prop="userPhone">
            <el-input v-model="form.model.userPhone" :maxlength="24"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$tc('company', 1)" prop="companyKey">
            <select-company
              v-model="form.model.companyKey"
              :label-all="false"
            ></select-company>
          </el-form-item>
          <el-form-item :label="$t('service')" prop="serviceId">
            <select-service-company
              v-model="form.model.serviceId"
              :company-key="form.model.companyKey"
              :label-all="false"
            ></select-service-company>
          </el-form-item>
          <el-form-item :label="$t('role')" prop="roleId">
            <select-role
              v-model="form.model.roleId"
              :company-key="form.model.companyKey"
              :label-all="false"
              :service-id="form.model.serviceId"
            ></select-role>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item>
          <router-link
            class="el-button el-button--text el-button--small"
            to="/users"
            tag="button"
          >
            {{ $t('cancel') }}
          </router-link>
          <el-button
            size="mini"
            type="primary"
            :loading="form.loading"
            @click="onCreateChild"
          >
            {{ $t('save') }}
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </el-card>
</template>
<script>
import create from '@/views/common/create'
import form from '@/views/common/form'
import model from './model'
import rules from './rules'

export default {
  name: 'UserCreate',
  extends: create('users', { model }),
  data() {
    return {
      form: form({ model, rules }),
    }
  },
  methods: {
    onChangeEmail(event) {
      const _this = this
      if (event && +event.length > 0) {
        _this.form.loading = true
        _this.$http
          .get(`users/${event}/findByEmail`)
          .then(result => {
            if (result.data) {
              _this.form.model = {
                ..._this.form.model,
                userName: result.data.userName,
                userPhone: result.data.userPhone,
                userFirstName: result.data.userFirstName,
                userLastName: result.data.userLastName,
              }
            } else {
              _this.form.model = {
                ..._this.form.model,
                userName: '',
                userPhone: '',
                userFirstName: '',
                userLastName: '',
              }
            }
          })
          .finally(() => {
            _this.form.loading = false
          })
      } else {
        _this.form.model = {
          ..._this.form.model,
          userName: '',
          userPhone: '',
          userFirstName: '',
          userLastName: '',
        }
      }
    },
    onCreateChild() {
      const _this = this
      _this.onSubmit({ action: 'hub' })
    },
  },
}
</script>
